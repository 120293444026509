<template>
  <b-container class="page-container">
    <Breadcrumbs currentpage="Kies je organisatie" />
    <h1>{{ $t('select-client-to-continue') }}</h1>
    <b-alert show variant="danger"
      ><font-awesome-icon icon="exclamation-circle" />
      {{ $t('account-bound-to-multiple-clients') }}
    </b-alert>
    <h2>{{ $t('select-a-client') }}</h2>
    <b-row class="pt-4">
      <b-col
        v-for="account in accounts"
        :key="account.ContactID"
        class="card-userswitch mt-4 mt-lg-0"
        xs="12"
        sm="12"
        md="12"
        lg="6"
        xl="4"
      >
        <b-card class="mb-4"
          ><p>
            {{ account.CompanyName }}
            <small>{{ account.FullNameWOSalutation }} </small>
          </p>
          <p></p>
          <b-button
            :value="account.ContactID"
            :disabled="!account.IsCustomerActive"
            @click="selectContactID"
            variant="primary"
            >{{ $t('continue-as') }} {{ account.CompanyName }}</b-button
          ></b-card
        >
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import Breadcrumbs from '@/components/header/BreadCrumbs'
import { copyCart } from '@/services/CartService'
export default {
  components: { Breadcrumbs },
  data() {
    return {}
  },
  computed: {
    accounts: function() {
      return this.$store.state.accounts
    }
  },
  methods: {
    async selectContactID(event) {
      await this.$store.commit('setContactID', {
        contactID: event.target.value
      })

      await copyCart()

      this.$router.push({
        name: 'index',
        params: { loggedIn: true, lang: this.$i18n.locale }
      })
    }
  }
}
</script>
